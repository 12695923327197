import React, { useMemo } from 'react';
import { ThemeProvider } from 'theme';
import {
  createSlice,
  initialState as appInitialState,
  StoreProvider,
} from 'store';
import { advanced as sagas } from 'store/sagas';
import { FilterState, ThemeProviderCacheConfig } from 'types';
import CXApi from 'api';
import AdvancedFilters, { AdvancedFiltersProps } from './AdvancedFilters';

interface StyleCacheConfig {
  stylesCacheConfig?: ThemeProviderCacheConfig;
}

export interface AdvancedFiltersWithProvidersProps
  extends AdvancedFiltersProps,
    StyleCacheConfig {
  WorkspaceId: string;
  ViewId: number;
  AdvancedFiltersGuid: string;
  initialState?: Partial<Pick<FilterState, 'advanced'>>;
  apiUrl: string;
}
/**
 * @description The AdvancedFiltersWithProviders component renders advanced filters. It optionally accepts an initial state to set these filters. This is a standalone component, pre-configured with both a store provider and a theme provider. For standalone applications, this component must be wrapped with the AuthProvider component from the @cx/login library. However, if the application is running within CX1 or CX2, it functions correctly without the AuthProvider.
 *
 *@example
 * ```tsx
 *    <AdvancedFiltersWithProviders
        apiUrl={process.env.API_URL}
        WorkspaceId={'067bbba0-211f-4834-b6d7-19c484e36517'}
        ViewId={4680}
        AdvancedFiltersGuid={'f9d6e74b-dfef-4714-a63d-6fc03a3a2664'}
        initialState={{
          advanced: {
             data: {
              SelectedFilters: [
                {
                  AttributeGUID: "62de4d61-a57b-4a6b-af3d-c2d04bb0ab35",
                  Label: "Alert Disposition",
                  Name: "DQDISPO",
                  Values: [
                      "New"
                  ]
                }
              ]
          },
          }
        }}
      />
 *```
 * @category Component
 */
function AdvancedFiltersWithProviders({
  apiUrl,
  WorkspaceId,
  ViewId,
  AdvancedFiltersGuid,
  initialState,
  stylesCacheConfig,
  ...props
}: AdvancedFiltersWithProvidersProps) {
  const api = useMemo(() => new CXApi(apiUrl), [apiUrl]);
  const componentInitialState = useMemo(
    () => ({
      WorkspaceId,
      ViewId,
      advanced: {
        ...appInitialState.advanced,
        ...(initialState?.advanced || {}),
        data: {
          ...appInitialState.advanced.data,
          ...(initialState?.advanced?.data || {}),
          AdvancedFiltersGUID: AdvancedFiltersGuid,
        },
      },
    }),
    [initialState?.advanced, ViewId, WorkspaceId]
  );
  const handleCreateSlice = (state: any) => {
    return (set: any, get: any, store: any) => ({
      ...createSlice({ ...state, ...componentInitialState })(set, get, store),
    });
  };
  if (!WorkspaceId) {
    console.error('WorkspaceId is required');
  }
  if (!ViewId) {
    console.error('ViewId is required');
  }
  if (!AdvancedFiltersGuid) {
    console.error('AdvancedFiltersGuid is required');
  }
  return (
    <ThemeProvider cacheConfig={stylesCacheConfig as any}>
      <StoreProvider
        initialState={componentInitialState}
        createSlice={handleCreateSlice}
        api={api}
        sagas={[sagas] as GeneratorFunction[]}
      >
        <AdvancedFilters {...props} />
      </StoreProvider>
    </ThemeProvider>
  );
}

export default AdvancedFiltersWithProviders;
