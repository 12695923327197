import React, { PropsWithChildren, useMemo } from 'react';
import { FilterState } from 'types';
import CXApi from 'api';
import { StoreProvider, type StoreProviderProps } from './context';
import { createSlice } from './slice';
import * as appSagas from './sagas';
import defaultInitialState from './initialState';

/**
 * @description App implementation of the StoreProvider component. This component is used to provide the store to the application.
 * @prop {React.ReactNode} children - The children of the component
 * @prop {any} initialState - Initial state of the store.
 * @prop {Function} createSlice - Function to create the state and actions of the store.
 * @prop {any} persistConfig - Persist configuration of the store.
 * @prop {GeneratorFunction[]} sagas - Sagas of the store
 * @category Component
 */

export type AppStoreProviderProps = PropsWithChildren<
  Pick<
    Partial<StoreProviderProps<Partial<FilterState>>>,
    'initialState' | 'middleware' | 'createSlice' | 'sagas'
  >
> & {
  apiUrl: string;
};

export default function AppStoreProvider({
  children,
  initialState = defaultInitialState,
  sagas,
  apiUrl,
  ...props
}: AppStoreProviderProps) {
  const api = useMemo(() => new CXApi(apiUrl), [apiUrl]);
  return (
    <StoreProvider
      api={api}
      sagas={[
        ...(Object.values(appSagas) as GeneratorFunction[]),
        ...(sagas || []),
      ]}
      initialState={initialState}
      createSlice={createSlice}
      {...props}
    >
      {children}
    </StoreProvider>
  );
}
