export const changeLabelToFriendlyName = ({
  label,
  DataType,
  SubAttributeName,
}: {
  label: string;
  DataType: string;
  SubAttributeName: string;
}) => {
  switch (label) {
    case 'ANY':
    case 'IN':
      return 'Any of these';
    case 'NOT EQUAL':
    case '<>':
      if (
        DataType === 'String' &&
        (SubAttributeName === 'response' || SubAttributeName === 'dimension')
      ) {
        return 'None of these';
      }

      return 'Is not';

    case 'EQUALS':
    case '=':
      if (
        DataType === 'Integer' ||
        DataType === 'Double' ||
        DataType === 'Decimal'
      ) {
        return 'Equal to';
      }
      if (DataType === 'Date') {
        return 'Equal to';
      }

      return 'Is exactly';

    case 'LIKE':
      return 'Includes';
    default:
      return label.toString();
  }
};
