import { request, isHosted } from '@cx/login';
import fetchLegacy from 'utils/legacyFetch';
import {
  AdvancedFilterType,
  DLAttributeDef,
  FavoriteFilterResponse,
  FullFavoriteFilterResponse,
  SaveFavoriteFilterBody,
  ApiType,
  FilteringSolutionEnum,
  AdvancedFiltersQuestionListParams,
  UpdateFiltersBody,
} from 'types';
import { GetFilterBarDataReturnType } from 'utils/filterBarHelpers';

/**
 * Represents an implementation of the ApiType interface.
 * @constructor  API_URL - The base url for the api.
 * @constructor fetchService - The fetch service to use for requests. When the app is hosted in CX it will use the {@link fetchLegacy} service if a legacy endpoint is available
 */
class CXApi implements ApiType {
  API_URL: string;

  private LEGACY_ENDPOINTS: { [key: string]: string } = {
    fetchDateQuickButtons: '/DateQuickButtons/GetQuickDateButtonsV2',
    fetchDropdownButtons: '/DateDropdown/GetDateDropdownV2',
    getSavedLanguages: `/Translation/GetSavedLanguages`,
  };

  private API_ENDPOINTS: { [key: string]: string } = {
    getFilterBar: `/api/FilterBar/GetFilterBar`,
    getFavoriteFilters: `/api/FavoriteFilters/GetDataFavoriteFilters`,
    updateFilters: `/api/filterbar/updatefilters`,
    getAdvancedFiltersQuestionList: `/api/filterbar/getadvancedfiltersquestionlist`,
    getQuestionDetails: `/api/filterbar/getquestiondetails`,
    getAttributeChoices: `/api/filterbar/getattributechoices`,
    fetchDateQuickButtons: `/api/DateQuickButtons/GetDateQuickButtons`,
    fetchDropdownButtons: `/api/DateDropDown/GetDateDropDown`,
    fetchFullFavoriteFilter: `/api/FavoriteFilters/GetFullFavFilters`,
    saveFavFilters: `/api/FavoriteFilters/SaveFavoriteFiltersV2`,
    deleteFavoriteFilter: `/api/FavoriteFilters/DeleteFavoriteFilters`,
    storeFilters: `/api/FilterBar/StoreFiltersOnCache`,
    getSavedLanguages: `/api/Translation/GetSavedLanguages`,
    getFilterBarState: `/api/FilterBar/GetFilterBarFilters`,
  };

  fetchService: (
    url: string,
    { contentType, body, ...options }: any
  ) => Promise<Response> = request;

  constructor(API_URL: string, fetchService = request) {
    this.API_URL = API_URL;
    if (fetchService) this.fetchService = fetchService;
  }

  private async requestAdapter(
    endpoint: string,
    options: any,
    type: 'text' | 'json' = 'json'
  ) {
    const fetchService =
      isHosted() &&
      Object.prototype.hasOwnProperty.call(this.LEGACY_ENDPOINTS, endpoint)
        ? fetchLegacy
        : request;
    const url =
      isHosted() &&
      Object.prototype.hasOwnProperty.call(this.LEGACY_ENDPOINTS, endpoint)
        ? this.LEGACY_ENDPOINTS[endpoint]
        : `${this.API_URL}${this.API_ENDPOINTS[endpoint]}`;
    const res = await fetchService(`${url}`, options);
    return res[type]?.() || res.json();
  }

  /**
   * Asynchronously retrieves filter bar data for a specific workspace and view.
   * @param WorkspaceId - The ID of the workspace to retrieve filter bar data for.
   * @param ViewId - The ID of the view within the workspace.
   * @param FilteringSolution - Optional parameter specifying the filtering solution.
   * @returns A Promise that resolves with the filter bar data.
   */
  getFilterBar = async (
    WorkspaceId: string,
    ViewId: number,
    FilteringSolution = ''
  ) =>
    this.requestAdapter(
      `getFilterBar`,
      {
        method: 'POST',
        body: { WorkspaceId, ViewId, FilteringSolution },
      },
      'json'
    );

  /**
   * Asynchronously retrieves favorite filters data from the server.
   * @param WorkspaceId - The ID of the workspace for which favorite filters are being retrieved.
   * @param HierarchyGuid - The GUID of the hierarchy for the parameters.
   * @param AdvanceFilterGuid - The GUID of the advanced filter data.
   * @returns A Promise that resolves to a FavoriteFilterResponse object.
   */

  getFavoriteFilters = async (
    WorkspaceId: string,
    HierarchyGuid: string,
    AdvanceFilterGuid: string,
    CacheVersion: string
  ): Promise<FavoriteFilterResponse> =>
    this.requestAdapter(
      `getFavoriteFilters`,
      {
        method: 'POST',
        body: {
          WorkspaceId,
          Parameters: HierarchyGuid,
          Data: AdvanceFilterGuid,
          ...(CacheVersion ? { CacheVersion } : {}),
        },
      },
      'json'
    );

  /**
   * Asynchronously updates hierarchy filters by sending a POST request to the specified API endpoint.
   * @param bodyRequest - The request body containing the filters to be updated.
   * @returns A Promise that resolves with the response data or null if an error occurs.
   */
  updateFilters = async (bodyRequest: UpdateFiltersBody): Promise<any | null> =>
    this.requestAdapter(
      `updateFilters`,
      {
        method: 'POST',
        body: bodyRequest,
      },
      'json'
    );

  /**
   * Retrieves a list of advanced filters questions based on the provided parameters.
   * @param {AdvancedFiltersQuestionListParams} params - Parameters for retrieving advanced filters questions.
   * @returns {Promise<AdvancedFilterType[] | null>} A promise that resolves to an array of AdvancedFilterType or null.
   */
  getAdvancedFiltersQuestionList = async ({
    WorkspaceId,
    AdvanceFiltersGuid,
    FilteringSolution = '' as FilteringSolutionEnum,
  }: AdvancedFiltersQuestionListParams): Promise<AdvancedFilterType[] | null> =>
    this.requestAdapter(
      `getAdvancedFiltersQuestionList`,
      {
        method: 'POST',
        body: {
          FilteringSolution,
          Parameters: [AdvanceFiltersGuid, WorkspaceId],
        },
      },
      'json'
    );

  /**
   * Asynchronously retrieves question details for an Advanced Filter based on the provided AttributeGUID and FilteringSolution.
   * @param AttributeGUID - The unique identifier of the attribute.
   * @param FilteringSolution - The filtering solution to be applied (optional).
   * @returns A Promise that resolves to a DLAttributeDef object or null.
   */
  getQuestionDetails = async (
    AttributeGUID: string,
    FilteringSolution = ''
  ): Promise<DLAttributeDef | null> =>
    this.requestAdapter(
      `getQuestionDetails`,
      {
        method: 'POST',
        body: {
          FilteringSolution,
          Parameters: AttributeGUID,
        },
      },
      'json'
    );

  /**
   * Asynchronously retrieves attribute choices for an Addvanced Filter based on the provided AttributeGUID and SubAttributeLabel.
   * @param AttributeGUID - The unique identifier of the attribute.
   * @param SubAttributeLabel - The label of the sub-attribute.
   * @param FilteringSolution - Optional filtering solution to apply.
   * @returns A Promise that resolves to a DLAttributeDef object or null.
   */
  getAttributeChoices = async (
    AttributeGUID: string,
    SubAttributeLabel: string,
    FilteringSolution = ''
  ): Promise<DLAttributeDef | null> =>
    this.requestAdapter(
      `getAttributeChoices`,
      {
        method: 'POST',
        body: {
          FilteringSolution,
          Parameters: [AttributeGUID, SubAttributeLabel],
        },
      },
      'json'
    );

  /** .
   * @param WorkspaceId - The ID of the workspace to fetch date quick buttons for.
   * @returns A Promise that resolves to a DLAttributeDef object representing the date quick buttons, or null if not found.
   */
  fetchDateQuickButtons = async (
    WorkspaceId: string,
    ClientGuid: string
  ): Promise<DLAttributeDef | null> =>
    this.requestAdapter(
      `fetchDateQuickButtons`,
      {
        method: 'POST',
        body: {
          WorkspaceId,
          Data: ClientGuid,
        },
      },
      'json'
    );

  /**
   * Fetches dropdown buttons for a specific workspace and year-to-date status.
   * @param WorkspaceId - The ID of the workspace for which dropdown buttons are being fetched.
   * @param IsYtd - A boolean indicating whether the dropdown buttons are for year-to-date.
   * @returns A Promise that resolves to DLAttributeDef object representing the fetched dropdown buttons, or null if the request fails.
   */
  fetchDropdownButtons = async (
    WorkspaceId: string,
    IsYtd: boolean,
    TimePeriodType: string
  ): Promise<DLAttributeDef | null> =>
    this.requestAdapter(
      `fetchDropdownButtons`,
      {
        method: 'POST',
        body: {
          WorkspaceId: String(WorkspaceId),
          IsYtd: Boolean(IsYtd),
          Parameters: TimePeriodType,
        },
      },
      'json'
    );

  /**
   * Fetches the full favorite filter based on the provided WorkspaceId and ViewId.
   * @param WorkspaceId The ID of the workspace for which the favorite filter is being fetched.
   * @param ViewId The ID of the view associated with the favorite filter.
   * @returns A Promise that resolves to a FullFavoriteFilterResponse or null if the request fails.
   */
  fetchFullFavoriteFilter = async (
    WorkspaceId: string,
    ViewId: boolean
  ): Promise<FullFavoriteFilterResponse | null> =>
    this.requestAdapter(
      `fetchFullFavoriteFilter`,
      {
        method: 'POST',
        body: {
          WorkspaceId: String(WorkspaceId),
          ViewId,
        },
      },
      'json'
    );

  /**
   * Asynchronously saves a favorite filter based on the provided body request.
   * @param bodyRequest
   * @returns
   */
  saveFavFilters = (
    bodyRequest: SaveFavoriteFilterBody
  ): Promise<string | null> =>
    this.requestAdapter(
      `saveFavFilters`,
      {
        method: 'POST',
        body: bodyRequest,
      },
      'json'
    );

  /**
   * Deletes a favorite filter for a specific workspace.
   * @param WorkspaceId - The ID of the workspace where the filter belongs.
   * @param FilterId - The ID of the filter to be deleted.
   * @returns A promise that resolves to a text response indicating the success of the deletion.
   */
  deleteFavoriteFilter = async (WorkspaceId: string, FilterId: number) =>
    this.requestAdapter(
      `deleteFavoriteFilter`,
      {
        method: 'POST',
        body: {
          WorkspaceId,
          ViewId: FilterId, // The api grabs the filter id from the ViewId property
        },
      },
      'text'
    );

  /**
   * Asynchronously stores filters on cache for a specific view.
   * @param ViewId - The ID of the view to store filters for.
   * @param Filters - The filters data to be stored.
   * @returns A promise that resolves with the result of storing the filters on cache.
   */
  storeFilters = async (
    ViewId: number,
    Filters: GetFilterBarDataReturnType
  ): Promise<any | null> =>
    this.requestAdapter(
      `storeFilters`,
      {
        method: 'POST',
        body: {
          Parameters: Filters,
          ViewId,
        },
      },
      'text'
    );

  /**
   * Retrieves the saved languages for a specific workspace using an asynchronous request.
   * @param WorkspaceGUID - The unique identifier of the workspace.
   * @returns A Promise that resolves with the saved languages data.
   */
  getSavedLanguages = async ({
    WorkspaceGUID,
  }: {
    WorkspaceGUID: string;
  }): Promise<any> =>
    this.requestAdapter(
      `getSavedLanguages`,
      {
        method: 'POST',
        body: {
          WorkspaceId: WorkspaceGUID,
        },
      },
      'json'
    );

  /**
   * Asynchronously retrieves filter bar data for a specific view.
   * @param ViewId - The identifier of the view for which filter bar data is requested.
   * @returns A promise that resolves to the filter bar data for the specified view.
   */
  getFilterBarState = async (
    ViewId: number
  ): Promise<GetFilterBarDataReturnType> =>
    this.requestAdapter(
      `getFilterBarState`,
      {
        method: 'POST',
        body: {
          ViewId,
        },
      },
      'json'
    );
}

export default CXApi;
