import React, { useMemo } from 'react';
import { ThemeProvider } from '@cx/ui';
import {
  createSlice,
  initialState as appInitialState,
  StoreProvider,
} from 'store';
import { ThemeProviderCacheConfig, FilterState } from 'types';
import { hierarchy as sagas } from 'store/sagas';
import CXApi from 'api';
import HierarchyFilters, { HierarchyFiltersProps } from './HierarchyFilters';

interface StyleCacheConfig {
  stylesCacheConfig?: ThemeProviderCacheConfig;
}

/**
 *  The props type for {@link HierarchyFiltersWithProviders}.
 */

export interface HierarchyFiltersWithProvidersProps
  extends HierarchyFiltersProps,
    StyleCacheConfig {
  WorkspaceId: string;
  ViewId: number;
  initialState?: Partial<Pick<FilterState, 'hierarchy'>>;
  apiUrl: string;
}

/**
 * @description The HierarchyFiltersWithProviders component renders hierarchy filters. It optionally accepts an initial state to set these filters. If no initial state is provided, the component fetches default values from the API, corresponding to the current workspace and view. This is a standalone component, pre-configured with both a store provider and a theme provider. For standalone applications, this component must be wrapped with the AuthProvider component from the @cx/login library. However, if the application is running within CX1 or CX2, it functions correctly without the AuthProvider.
 *
 *@example
 * ```tsx
 *     <HierarchyFiltersWithProviders
        apiUrl={"api-url"}
        WorkspaceId={'workspace-id'}
        ViewId={view-id}
        onHierarchyFilterChange={(filter) =>
          console.log(JSON.stringify(filter.SelectedFilters))
        }
        initialState={{
          hierarchy: {
            data: {
              SelectedFilters: {
                DQSITE: {
                  values: ['Boston', 'Baltimore', 'Charlotte'],
                },
              },
            },
          },
        }}
      />
 *```
 * @category Component
 */

function HierarchyFiltersWithProviders({
  apiUrl,
  initialState,
  stylesCacheConfig,
  onHierarchyFilterChange,
  WorkspaceId,
  ViewId,
  ...props
}: HierarchyFiltersWithProvidersProps) {
  const api = useMemo(() => new CXApi(apiUrl), [apiUrl]);
  const componentInitialState = useMemo(
    () => ({
      WorkspaceId,
      ViewId,
      hierarchy: {
        ...appInitialState.hierarchy,
        ...(initialState?.hierarchy || {}),
      },
    }),
    [initialState?.hierarchy, ViewId, WorkspaceId]
  );
  const handleCreateSlice = (state: any) => {
    return (set: any, get: any, store: any) => ({
      ...createSlice({ ...state, ...componentInitialState })(set, get, store),
    });
  };
  if (!WorkspaceId) {
    console.error('WorkspaceId is required');
  }
  if (!ViewId) {
    console.error('ViewId is required');
  }

  return (
    <ThemeProvider cacheConfig={stylesCacheConfig as any}>
      <StoreProvider
        initialState={componentInitialState}
        createSlice={handleCreateSlice}
        api={api}
        sagas={[sagas] as GeneratorFunction[]}
      >
        <HierarchyFilters
          {...props}
          onHierarchyFilterChange={onHierarchyFilterChange}
        />
      </StoreProvider>
    </ThemeProvider>
  );
}

export default HierarchyFiltersWithProviders;
